import { memo, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ProtectedRoute } from 'components/RouteGuard';
import { Dashboard } from 'containers/Dashboard/Loadable';
import { Admin } from 'containers/Admin/Loadable';
import LoginCallback from 'containers/LoginCallback/LoginCallback.container';

import { getItem } from 'utils/localStorage';
import PropTypes from 'prop-types';
import './style.css';
import { removeItem } from 'utils/localStorage';
// import firebase from 'utils/firebase_app';

const App = memo(({ initApp, setLoadingApp }) => {
  useEffect(() => {
    // setup
    removeItem('sessionExpired');
    redirectToSSOPage();
  }, []);

  const redirectToSSOPage = async () => {
    const token = getItem('SMART_OFFICE_TOKEN');
    if (!token) {
      let response_type = encodeURIComponent('id_token token');
      let scope = encodeURIComponent('openid');
      let nonce = encodeURIComponent('anything');

      let state = encodeURIComponent('anything');
      let client_id = encodeURIComponent(
        `${process.env.REACT_APP_SSO_CLIENT_ID}`,
      );
      let redirect_uri = encodeURIComponent(
        `${process.env.REACT_APP_SSO_LOGIN_CALLBACK}`,
      );
      var tenantCode = '';
      if (window.location.hostname) {
        redirect_uri =
          window.location.protocol +
          '//' +
          window.location.hostname +
          ':' +
          window.location.port +
          '/loginCallback';
        tenantCode = window.location.hostname.split('.')[0];
      }
      window.location.href = `${process.env.REACT_APP_SSO_URL_LOGIN}?response_type=${response_type}&scope=${scope}&nonce=${nonce}&state=${state}&client_id=${client_id}&redirect_uri=${redirect_uri}&tenant_code=${tenantCode}`;
    } else {
      await initApp();
      setLoadingApp();
      // const messaging = firebase.messaging();
      //
      // messaging
      //   .requestPermission()
      //   .then(() => {
      //     return messaging.getToken();
      //   })
      //   .then(data => {
      //     console.warn('token', data);
      //   });
      //
      // messaging.onMessage(payload => {
      //   console.log('Message received. ', payload);
      // });
    }
  };

  return (
    <div className="container">
      <Switch>
        <ProtectedRoute path={'/dashboard'} component={Dashboard} />
        <ProtectedRoute path={'/admin'} component={Admin} />
        <Route path={'/loginCallback'} component={LoginCallback} />
        <Redirect from={'/'} to={'/dashboard'} />
      </Switch>
    </div>
  );
});

App.propTypes = {
  initApp: PropTypes.func,
  setLoadingApp: PropTypes.func,
};
App.defaultProps = {
  initApp() {},
  setLoadingApp() {},
};

export default App;
