import { createSelector } from 'redux-starter-kit';

const departmentSelector = createSelector(
  ['department'],
  department => department,
);

export const departmentsSelector = createSelector(
  [departmentSelector],
  department => department.departments,
);
