import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import App from './containers/App/App.container';
import reportWebVitals from './reportWebVitals';
import './locales/i18n';
import './global/style.css';
import './global/override-ant.css';
import { history } from 'utils/history';
import './assets/fonts/fontawesome-pro-5.11.1-web/css/all.min.css';
import store from './store';
ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
