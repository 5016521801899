import axios from 'axios';
import qs from 'qs';
import axiosSso from './axios_sso';
import { getItem, setItem } from 'utils/localStorage';
import { openSessionExpiredModal } from 'utils/helper';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
  timeout: 100000,
});

// Request interceptor for API calls
instance.interceptors.request.use(
  async config => {
    const idToken = getItem('SMART_OFFICE_TOKEN');
    const sessionId = getItem('sessionId');
    config.headers = {
      Authorization: `Bearer ${idToken}`,
      TenantCode: 'mobifone.vn',
      SessionId: sessionId,
      common: { 'Content-Type': 'application/json; charset=utf-8' },
    };
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

instance.interceptors.response.use(
  res => res,
  async error => {
    const originalRequest = error.config;
    if (
      error &&
      (error?.response?.data?.error === 'authenticate.BlacklistJwtException' ||
        error?.response?.data?.message === 'refreshToken.ExpiredJwtException' ||
        error?.data?.message === 'authenticate.jwtExpired')
    ) {
      let sessionExpired = await getItem('sessionExpired');
      if (!sessionExpired) {
        await openSessionExpiredModal();
      }
      return null;
    } else if (
      error &&
      error?.response?.data?.error === 'authenticate.jwtExpired' &&
      !originalRequest._retry
    ) {
      const tokenData = await axiosSso.post('/refresh');
      if (tokenData) {
        await setItem('SMART_OFFICE_TOKEN', tokenData.data?.id_token);
        await setItem('expires_in', tokenData.data?.expires_in);
        await setItem('createTokenTime', Date.now());
      }
      originalRequest._retry = true;
      return instance(originalRequest);
    } else {
      return Promise.reject(error);
    }
  },
);

export default instance;
