import { createSlice } from 'redux-starter-kit';
// import moment from 'moment';

const initialState = {
  departments: [],
  positions: [],
  roles: [],
  apps: [],
  users: [],
  user: null,
  usersNotInApp: [],
  error: null,
  totalSize: 0,
  me: null,
  userInfo: null,
  workspaceApps: [],
  department: null,
};
// console.log('createSlice account');
export default createSlice({
  slice: 'account',
  initialState,
  reducers: {
    listDepartmentSuccess: (state, action) => ({
      ...state,
      departments: action.payload.result,
    }),
    listPositionsSuccess: (state, action) => ({
      ...state,
      positions: action.payload.result,
    }),
    listRolesSuccess: (state, action) => ({
      ...state,
      roles: action.payload.result,
    }),
    listAppsSuccess: (state, action) => ({
      ...state,
      apps: action.payload.result,
    }),
    listUsersSuccess: (state, action) => ({
      ...state,
      users: action.payload.result.users,
      totalSize: action.payload.result.count,
    }),
    getUserSuccess: (state, action) => {
      return {
        ...state,
        user: action.payload.result,
      };
    },
    listUsersNotInAppSuccess: (state, action) => ({
      ...state,
      usersNotInApp: action.payload.result,
    }),
    setLoggedInUser: (state, action) => ({
      ...state,
      me: action.payload.result,
    }),

    setUserInfo: (state, action) => {
      return {
        ...state,
        userInfo: action.payload.result,
      };
    },

    updateUserInfoSuccess: (state, action) => {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...action.payload.result,
          // birthDay: moment(action.payload.result.birthDay),
        },
      };
    },

    updateUserFail: (state, action) => {
      return {
        ...state,
        error: true,
      };
    },

    listWorkspaceAppsSuccess: (state, action) => ({
      ...state,
      workspaceApps: action.payload.result,
    }),

    inactiveUserSuccess: (state, action) => ({
      ...state,
      users: state.users.map(item =>
        item.id === action.payload.result.id ? action.payload.result : item,
      ),
    }),

    activeUserSuccess: (state, action) => ({
      ...state,
      users: state.users.map(item =>
        item.id === action.payload.result.id ? action.payload.result : item,
      ),
    }),

    setDepartmentSuccess: (state, action) => ({
      ...state,
      department: action.payload.result,
    }),
    removeError: (state, action) => ({
      ...state,
      error: null,
    }),
    uploadAvatarSuccess: state => {
      return {
        ...state,
        reloadAvatar: true,
      };
    },
  },
});
