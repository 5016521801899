import { createSlice } from 'redux-starter-kit';

const initialState = {
  paymentHistory: [],
  paymentInfo: [],
  paymentTenantApps: [],
};
// console.log('createSlice');
export default createSlice({
  slice: 'payment',
  initialState,
  reducers: {
    listPaymentHistorySuccess: (state, action) => ({
      ...state,
      paymentHistory: action.payload.result,
    }),
    listPaymentInfoSuccess: (state, action) => ({
      ...state,
      paymentInfo: action.payload.result.infoList,
      paymentTenantApps: action.payload.result.tenantApplicationList,
    }),
  },
});
