import { connect } from 'react-redux';

import { actions, selectors } from 'store/app';
import LoginCallback from './index';

const mapStateToProps = state => ({
  token: selectors.tokenSelector(state),
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LoginCallback);
