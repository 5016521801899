import { createSlice } from 'redux-starter-kit';

const initialState = {
  tokenData: null,
};
export default createSlice({
  slice: 'sso',
  initialState,
  reducers: {
    refreshTokenSuccess: (state, action) => ({
      ...state,
      tokenData: action.payload.result,
    }),
  },
});
