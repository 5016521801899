import { createSelector } from 'redux-starter-kit';

const paymentSelector = createSelector(['payment'], payment => payment);

export const paymentHistorySelector = createSelector(
  [paymentSelector],
  payment => payment.paymentHistory,
);

export const paymentInfoSelector = createSelector(
  [paymentSelector],
  payment => payment.paymentInfo,
);

export const paymentTenantAppsSelector = createSelector(
  [paymentSelector],
  payment => payment.paymentTenantApps,
);
