import axios from 'store/axios';
import { formatQuery } from '../../utils/utils';

export const getPaymentHistory = data =>
  axios
    .get('/account/paymentHistory/getHistory', {
      params: formatQuery(data),
    })
    .then(res => {
      // console.log(res);
      return res.data;
    });

export const getPaymentInfo = data =>
  axios
    .get('/account/paymentInfo/getInfo', {
      params: formatQuery(data),
    })
    .then(res => {
      // console.log(res);
      return res.data;
    });
