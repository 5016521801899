import { createSlice } from 'redux-starter-kit';

const initialState = {
  departments: [],
  error: null,
};
export default createSlice({
  slice: 'department',
  initialState,
  reducers: {
    listDepartmentSuccess: (state, action) => ({
      ...state,
      departments: action.payload.result,
    }),
  },
});
