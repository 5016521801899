import { createSlice } from 'redux-starter-kit';

const initialState = {
  tenantApps: [],
  otherApps: [],
  usersWithDepartment: [],
  reloadLogo: false,
};
// console.log('createSlice');
export default createSlice({
  slice: 'application',
  initialState,
  reducers: {
    listUsersAppsSuccess: (state, action) => ({
      ...state,
      tenantApps: action.payload.result.tenantApps,
      otherApps: action.payload.result.otherApps,
    }),
    listUsersWithDepartmentSuccess: (state, action) => ({
      ...state,
      usersWithDepartment: action.payload.result,
    }),
    tenantAppSuccess: (state, action) => ({
      ...state,
      tenantApp: action.payload.result,
      reloadLogo: false,
    }),
    uploadTenantLogoSuccess: state => {
      return {
        ...state,
        reloadLogo: true,
      };
    },
  },
});
