import React from 'react';
import { Route } from 'react-router-dom';
// import { TYPE_LOCAL_STORAGE } from 'utils/constants';
// import { getItem, removeItem } from 'utils/localStorage';
import useLoadingApp from 'hooks/useLoadingApp';

export const PublicRoute = ({ component: Component, ...rest }) => {
  const loadingApp = useLoadingApp();
  return (
    loadingApp || (
      <Route
        {...rest}
        render={props => {
          // if (!auth()) {
          return <Component {...props} />;
          // } else {
          //   const url_redirect = getItem(TYPE_LOCAL_STORAGE.URL_REDIRECT);
          //   removeItem(TYPE_LOCAL_STORAGE.URL_REDIRECT);
          //   return (
          //     <Redirect
          //       to={{
          //         pathname: url_redirect || '/',
          //       }}
          //     />
          //   );
          // }
        }}
      />
    )
  );
};
