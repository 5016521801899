import createOperation from '../createOperation';
import * as authService from './service';
import slice from './slice';
const { actions } = slice;

export const getAccount = createOperation({
  async process({ dispatch }) {
    const account = await authService.getAccount();
    // console.log('account getAccount from app', account);
    if (account) {
      //set header

      return account;
    }
    return null;
  },
});
export const setLoadingApp = createOperation({
  actions: {
    successAction: actions.setLoadingApp(),
  },
});
