import { connect } from 'react-redux';
import { actions, selectors } from 'store/account';
import { actions as actionsApp } from 'store/app';
import { actions as actionsApplication } from 'store/applications';
import App from './index';

const mapStateToProps = state => ({
  user: selectors.meSelector(state),
});
const mapDispatchToProps = dispatch => ({
  initApp: () => {
    Promise.all([
      dispatch(actions.getUserInfo()),
      dispatch(actions.getAccount()),
      dispatch(actionsApplication.getTenantApp()),
    ]);
  },
  setLoadingApp: () => dispatch(actionsApp.setLoadingApp()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
