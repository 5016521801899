import { createSelector } from 'redux-starter-kit';

const applicationSelector = createSelector(
  ['application'],
  application => application,
);

export const tenantAppsSelector = createSelector(
  [applicationSelector],
  application => application.tenantApps,
);

export const otherAppsSelector = createSelector(
  [applicationSelector],
  application => application.otherApps,
);

export const usersWithDepartmentSelector = createSelector(
  [applicationSelector],
  application => application.usersWithDepartment,
);

export const tenantAppSelector = createSelector(
  [applicationSelector],
  application => application.tenantApp,
);

export const reloadSelector = createSelector(
  [applicationSelector],
  application => application.reloadLogo,
);
