import axios from 'store/axios';
import { formatQuery } from 'utils/utils';

export const getAccount = query => {
  // console.log('cuong7');
  return axios
    .get('/account/getAccount/user-1', { params: formatQuery(query) })
    .then(res => {
      // console.log('getAccount', res);
      return res;
    });
};
