import createOperation from '../createOperation';
import * as accountService from './service';
import _ from 'lodash';
import slice from './slice';
import { openAlertModal } from 'utils/helper';
import MD5 from 'crypto-js/md5';
import moment from 'moment';
import * as applicationService from '../applications/service';
// import { message } from 'antd';
const { actions } = slice;

export const createUser = createOperation({
  async process({ payload: { data, listApp, date, depRootId } }) {
    // console.log(data);
    let params = {
      ...data,
    };
    if (data.fullName !== null && data.fullName.trim() !== '') {
      const list = [],
        depts = [],
        objDeptApp = {},
        objDept = {};
      _.forEach(listApp, obj => {
        if (data.permission === 'role-owner') {
          obj.appRole = 'admin';
        } else {
          obj.appRole = 'member';
        }
        list.push(obj);
      });

      if (data.permission === 'role-admin') {
        objDept.deptId = data.deptAdmin;
        objDept.deptType = 'base';
        depts.push(objDept);
      } else if (data.permission === 'role-owner') {
        objDept.deptId = depRootId;
        objDept.deptType = 'base';
        depts.push(objDept);
      }
      objDeptApp.deptId = data.deptId;
      objDeptApp.deptType = 'app';

      depts.push(objDeptApp);

      if (date != null) {
        params.birthday = date.toDate().getTime();
      }
      params.roleId = data.permission;
      params.apps = list;
      params.departments = depts;
      // console.log(params);
      const user = await accountService.createUser(params);
      if (user != null) {
        openAlertModal('success', 'Thông báo!', 'Thêm người dùng thành công!');
        return user;
      }
    } else {
      openAlertModal('warning', 'Chú ý', 'Họ và tên không hợp lệ!');
    }
  },
});

export const listDepartments = createOperation({
  actions: {
    successAction: actions.listDepartmentSuccess,
  },
  async process() {
    return await accountService.getDepartments();
  },
});

export const listPositions = createOperation({
  actions: {
    successAction: actions.listPositionsSuccess,
  },
  async process() {
    return await accountService.getPositions();
  },
});

export const listRoles = createOperation({
  actions: {
    successAction: actions.listRolesSuccess,
  },
  async process() {
    return await accountService.getRoles();
  },
});

export const listApps = createOperation({
  actions: {
    successAction: actions.listAppsSuccess,
  },
  async process() {
    return await accountService.getApps();
  },
});

export const listUser = createOperation({
  actions: {
    successAction: actions.listUsersSuccess,
  },
  async process({ payload: data }) {
    const params = {};
    params.page = data.page;
    params.size = data.size;
    // params.sort = 'updateTime,desc';
    params.keyword = data.keyword;
    params.birthDay = data.birthDay;
    params.email = data.email;
    params.fullName = data.fullName;
    params.googleAccount = data.googleAccount;
    params.microsoftAccount = data.microsoftAccount;
    params.phone = data.phone;
    params.shortName = data.shortName;
    params.userName = data.userName;
    params.deptId = data.deptId;
    params.usingAdvanceSearch = data.usingAdvanceSearch;
    // console.log('params ListUser', params);
    return await accountService.getUser(params);
  },
});

export const getUserByUserId = createOperation({
  actions: {
    successAction: actions.getUserSuccess,
  },
  async process({ payload: userId }) {
    console.log(userId);
    const params = {};
    params.userId = userId;
    return await accountService.getUserByUserId(params);
  },
});

export const listUserNotInApp = createOperation({
  actions: {
    successAction: actions.listUsersNotInAppSuccess,
  },
  async process({ payload: data }) {
    const params = {};
    params.appId = data.appId;
    params.deptIds = data.deptIds;
    // console.log(data);
    // console.log(params);
    return await accountService.getUserNotInApp(params);
  },
});

export const inactiveUser = createOperation({
  actions: {
    successAction: actions.inactiveUserSuccess,
  },
  async process({ payload: { id } }) {
    const params = {};
    params.userID = id;
    const user = await accountService.inactiveUser(params);
    if (user != null) {
      openAlertModal('success', 'Thông báo!', 'Khóa tài khoản thành công');
      return user;
    }
  },
});

export const activeUser = createOperation({
  actions: {
    successAction: actions.activeUserSuccess,
  },
  async process({ payload: { id } }) {
    const params = {};
    params.userID = id;
    const user = await accountService.activeUser(params);
    if (user != null) {
      openAlertModal('success', 'Thông báo!', 'Kích hoạt tài khoản thành công');
      return user;
    }
  },
});

export const updateUser = createOperation({
  async process({ payload: { data, listApp, date, depRootId } }) {
    let params = {
      ...data,
    };
    if (data.fullName !== null && data.fullName.trim() !== '') {
      const list = [],
        depts = [],
        objDeptApp = {},
        objDept = {};
      _.forEach(listApp, obj => {
        if (data.permission === 'role-owner') {
          obj.appRole = 'admin';
        } else {
          if (obj && obj.appRole === '') {
            obj.appRole = 'member';
          }
        }
        list.push(obj);
      });

      if (data.permission === 'role-admin') {
        objDept.deptId = data.deptAdmin;
        objDept.deptType = 'base';
        depts.push(objDept);
      } else if (data.permission === 'role-owner') {
        objDept.deptId = depRootId;
        objDept.deptType = 'base';
        depts.push(objDept);
      }
      objDeptApp.deptId = data.deptId;
      objDeptApp.deptType = 'app';

      depts.push(objDeptApp);

      if (date != null) {
        params.birthday = date.toDate().getTime();
      }
      params.roleId = data.permission;
      params.apps = list;
      params.departments = depts;
      // console.log(params);
      const user = await accountService.updateUser(params);
      if (user != null) {
        openAlertModal(
          'success',
          'Thông báo!',
          'Cập nhật thông tin người dùng thành công',
        );
        return user;
      }
    } else {
      openAlertModal('warning', 'Chú ý', 'Họ và tên không hợp lệ');
    }
  },
});

export const getAccount = createOperation({
  actions: {
    successAction: actions.setLoggedInUser,
  },
  async process() {
    return await accountService.getAccount();
  },
});

export const changePassword = createOperation({
  async process({ payload: { data } }) {
    let params = {
      ...data,
    };

    params.oldPass = data.oldPass.trim();
    params.newPass = data.newPass.trim();
    // console.log(params);

    const user = await accountService.changePassword(params);
    if (user != null) {
      openAlertModal(
        'success',
        'Thông báo!',
        'Thay đổi thông tin mật khẩu thành công',
      );
      return user;
    }
  },
});

export const getUserInfo = createOperation({
  actions: {
    successAction: actions.setUserInfo,
  },
  async process() {
    const user = await accountService.getUserInfo();
    if (user.birthDay != null) {
      user.birthDay = moment(user.birthDay, 'YYYY-MM-DDTHH:mm:ss');
    }
    user.positionId = user?.position;
    if (!_.isEmpty(user.departments)) {
      _.forEach(user.departments, obj => {
        if (obj.deptType === 'app') {
          user.deptId = obj.deptId;
        } else {
          user.deptAdmin = obj.deptId;
        }
      });
    }
    user.permission = user?.roleId;

    return user;
  },
});

export const updateUserInfo = createOperation({
  actions: {
    successAction: actions.updateUserInfoSuccess,
    failedAction: actions.updateUserFail,
  },
  async process({ payload: { data, listApp, date, depRootId } }) {
    let params = {
      ...data,
    };
    // console.log('params before', params);
    // console.log('listApp', listApp);
    const list = [],
      depts = [],
      objDeptApp = {},
      objDept = {};
    _.forEach(listApp, obj => {
      if (data.permission === 'role-owner') {
        obj.appRole = 'admin';
      } else {
        obj.appRole = 'member';
      }
      list.push(obj);
    });

    if (data.permission === 'role-admin') {
      objDept.deptId = data.deptAdmin;
      objDept.deptType = 'base';
      depts.push(objDept);
    } else if (data.permission === 'role-owner') {
      objDept.deptId = depRootId;
      objDept.deptType = 'base';
      depts.push(objDept);
    }
    objDeptApp.deptId = data.deptId;
    objDeptApp.deptType = 'app';

    depts.push(objDeptApp);

    if (date != null) {
      params.birthday = date.toDate().getTime();
    }
    params.roleId = data.permission;
    params.apps = list;
    params.departments = depts;
    // console.log('params after', params);
    const user = await accountService.updateUserInfo(params);
    if (user != null) {
      openAlertModal(
        'success',
        'Thông báo!',
        'Cập nhật thông tin người dùng thành công',
      );
      return user;
    }
  },
});

export const importUsers = createOperation({
  async process({ payload: { data } }) {
    if (
      data != null &&
      data.importUserFormList &&
      data.importUserFormList.length > 0
    ) {
      const response = await accountService.importUsers(data);
      if (response != null && response.status === 200) {
        openAlertModal(
          'success',
          'Thông báo!',
          'Thêm danh sách người dùng thành công',
        );
        return response.data;
      } else if (response !== null) {
        let messageError = response.data.message;
        if (messageError === 'import.unknown.Exception') {
          messageError = 'Lỗi không xác định';
        }
        openAlertModal('error', 'Chú ý!', messageError, 5);
        return response.data;
      } else {
        openAlertModal('error', 'Chú ý!', 'Lỗi không xác định !', 5);
        return null;
      }
    } else {
      openAlertModal(
        'error',
        'Chú ý!',
        'File không đúng định dạng hoặc không có dữ liệu !',
        5,
      );
      return null;
    }
  },
});

export const getWorkspaceApps = createOperation({
  actions: {
    successAction: actions.listWorkspaceAppsSuccess,
  },
  async process() {
    return await accountService.getWorkspaceApps();
  },
});

export const getDepartment = createOperation({
  actions: {
    successAction: actions.setDepartmentSuccess,
  },
  async process({ payload: { deptId } }) {
    const params = {};
    params.deptId = deptId;
    const dept = await accountService.getDepartment(params);

    return dept;
  },
});

export const removeError = createOperation({
  actions: {
    successAction: actions.setDepartmentSuccess,
  },
});

export const uploadAvatar = createOperation({
  actions: {
    successAction: actions.uploadAvatarSuccess,
  },
  async process({ payload: { file } }) {
    let formData = new FormData();
    formData.append('file', file);
    const res = await accountService.uploadAvatar(formData);
    if (res != null) {
      openAlertModal('success', 'Thông báo!', 'Upload avatar thành công');
      return res;
    }
  },
});
