import createOperation from '../createOperation';
import * as ssoService from './service';
import slice from './slice';
const { actions } = slice;

export const refreshToken = createOperation({
  actions: {
    successAction: actions.refreshTokenSuccess,
  },
  async process() {
    // console.log('process');
    return await ssoService.refreshToken();
  },
});
