import { createSlice } from 'redux-starter-kit';

const initialState = {
  loading: true,
  token: null,
};

export default createSlice({
  slice: 'app',
  initialState,
  reducers: {
    setLoadingApp: (state, { payload }) => ({
      ...state,
      loading: false,
    }),

    setToken: (state, action) => ({
      ...state,
      token: action.payload,
    }),
  },
});
