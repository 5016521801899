import React from 'react';
import { useSelector } from 'react-redux';

const useLoadingApp = () => {
  const { loading } = useSelector(state => state.app);

  return loading ? <div /> : false;
};

export default useLoadingApp;
