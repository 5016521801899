import axios from 'store/axios';
import { formatQuery } from 'utils/utils';

export const createUser = data => {
  return axios.post('/account/user/createUser', data).then(res => res.data);
};

export const getUser = data =>
  axios
    .get('/account/user/getUsersPaging', {
      params: formatQuery(data),
    })
    .then(res => {
      // console.log(res);
      return res.data;
    });

export const getUserByUserId = data =>
  axios
    .get('/account/user/getUserById', {
      params: formatQuery(data),
    })
    .then(res => {
      // console.log(res);
      return res.data;
    });

export const getDepartments = () =>
  axios.get('/account/department/getDepartments').then(res => res.data);

export const getPositions = () =>
  axios.get('/account/position/getPositions').then(res => res.data);

export const getRoles = () =>
  axios.get('/account/role/getRoles').then(res => res.data);

export const getApps = () =>
  axios.get('/account/tenantApp/getApps').then(res => res.data);

export const inactiveUser = data => {
  return axios
    .put('/account/user/inactiveUser', null, {
      params: formatQuery(data),
    })
    .then(res => res.data);
};

export const activeUser = data => {
  return axios
    .put('/account/user/activeUser', null, {
      params: formatQuery(data),
    })
    .then(res => res.data);
};

export const updateUser = data => {
  return axios.put('/account/user/updateUser', data).then(res => res.data);
};

export const getAccount = () =>
  axios.get('/account/getAccount').then(res => res.data);

export const changePassword = data => {
  return axios
    .put('/account/user/changePassword', null, {
      params: formatQuery(data),
    })
    .then(res => res.data);
};

export const getUserInfo = () =>
  axios.get('/account/user/getUserInfo').then(res => res.data);

export const updateUserInfo = data => {
  return axios.put('/account/user/updateUserInfo', data).then(res => res.data);
};

export const importUsers = data =>
  axios
    .post('/account/import/importUsers', data)
    .then(res => {
      return res;
    })
    .catch(error => {
      if (error.response) {
        return error.response;
      }
    });

export const getWorkspaceApps = () =>
  axios.get('/account/tenantApp/getWorkspaceApps').then(res => res.data);

export const getDepartment = id =>
  axios
    .get('/account/department/getDepartment', {
      params: formatQuery(id),
    })
    .then(res => {
      // console.log(res);
      return res.data;
    });

export const getUserNotInApp = data =>
  axios
    .post('/account/tenantApp/getUserNotInAppOfDepartments', data)
    .then(res => {
      // console.log(res);
      return res.data;
    });

export const uploadAvatar = formData => {
  return axios
    .post('/account/user/uploadAvatar', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => res.data);
};
