import createOperation from '../createOperation';
import * as departmentService from './service';
import slice from './slice';
import { openAlertModal } from 'utils/helper';
const { actions } = slice;

export const listDepartments = createOperation({
  actions: {
    successAction: actions.listDepartmentSuccess,
  },
  async process() {
    return await departmentService.getDepartments();
  },
});

export const createDepartment = createOperation({
  async process({ payload: { data, deptParentId } }) {
    // console.log(data);
    let params = {
      ...data,
    };

    // console.log(params);
    const dept = await departmentService.createDepartment(params);
    if (dept != null) {
      openAlertModal('success', 'Thông báo!', 'Thêm đơn vị thành công');
      return dept;
    }
  },
});

export const updateDepartment = createOperation({
  async process({ payload: { data, deptParentId } }) {
    // console.log(data);
    let params = {
      ...data,
    };

    // console.log(params);
    const dept = await departmentService.updateDepartment(params);
    if (dept != null) {
      openAlertModal('success', 'Thông báo!', 'Cập nhật đơn vị thành công');
      return dept;
    }
  },
});

export const inactiveDepartment = createOperation({
  async process({ payload: { id } }) {
    const params = {};
    params.deptId = id;
    const response = await departmentService.inactiveDepartment(params);
    // console.log(response);
    if (response != null && response.status === 200) {
      await openAlertModal('success', 'Thông báo!', 'Khoá đơn vị thành công');
      return response.data;
    } else if (response !== null) {
      let messageError = response.data.message;
      if (messageError === 'department.userDepartmentExisted.Exception') {
        messageError =
          'Phòng ban đang tồn tại thành viên, không được phép khóa!';
      }
      await openAlertModal('error', 'Chú ý!', messageError, 5);
      return response.data;
    } else {
      await openAlertModal('error', 'Chú ý!', 'Lỗi không xác định !', 5);
      return null;
    }
  },
});

export const activeDepartment = createOperation({
  async process({ payload: { id } }) {
    const params = {};
    params.deptId = id;
    const response = await departmentService.activeDepartment(params);
    // console.log(response);
    if (response != null && response.status === 200) {
      await openAlertModal(
        'success',
        'Thông báo!',
        'Mở khoá đơn vị thành công',
      );
      return response.data;
    } else if (response !== null) {
      let messageError = response.data.message;
      if (messageError === 'department.userDepartmentExisted.Exception') {
        messageError =
          'Phòng ban đang tồn tại thành viên, không được phép khóa!';
      }
      await openAlertModal('error', 'Chú ý!', messageError, 5);
      return response.data;
    } else {
      await openAlertModal('error', 'Chú ý!', 'Lỗi không xác định !', 5);
      return null;
    }
  },
});
