import { getItem, removeItem } from 'utils/localStorage';

export const logout = async () => {
  await removeItem('SMART_OFFICE_TOKEN');
  await removeItem('sessionExpired');
  let client_id = encodeURIComponent(process.env.REACT_APP_SSO_CLIENT_ID);
  let redirect_uri = encodeURIComponent(
    process.env.REACT_APP_SSO_LOGOUT_CALLBACK,
  );
  let sessionId = encodeURIComponent(getItem('sessionId'));
  let userId = encodeURIComponent(getItem('USID'));
  const url = `${process.env.REACT_APP_SSO_URL_LOGOUT}?client_id=${client_id}&redirect_uri=${redirect_uri}&userId=${userId}&sessionId=${sessionId}`;
  window.location.href = url;
};
