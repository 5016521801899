import { createSelector } from 'redux-starter-kit';

const accountSelector = createSelector(['account'], account => account);

export const departmentsSelector = createSelector(
  [accountSelector],
  account => account.departments,
);

export const positionsSelector = createSelector(
  [accountSelector],
  account => account.positions,
);

export const rolesSelector = createSelector(
  [accountSelector],
  account => account.roles,
);

export const appsSelector = createSelector(
  [accountSelector],
  account => account.apps,
);

export const usersSelector = createSelector(
  [accountSelector],
  account => account.users,
);

export const userSelector = createSelector(
  [accountSelector],
  account => account.user,
);

export const usersNotInAppSelector = createSelector(
  [accountSelector],
  account => account.usersNotInApp,
);

export const totalSizeSelector = createSelector(
  [accountSelector],
  account => account.totalSize,
);

export const meSelector = createSelector(
  [accountSelector],
  account => account.me,
);

export const userInfoSelector = createSelector(
  [accountSelector],
  account => account.userInfo,
);

export const workspaceAppsSelector = createSelector(
  [accountSelector],
  account => account.workspaceApps,
);
export const departmentSelector = createSelector(
  [accountSelector],
  account => account.department,
);
export const reloadSelector = createSelector(
  [accountSelector],
  application => application.reloadAvatar,
);
