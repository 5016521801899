import axios from 'store/axios';
import { formatQuery } from 'utils/utils';

export const getDepartments = () =>
  axios.get('/account/department/getDepartments').then(res => res.data);

export const createDepartment = data => {
  return axios
    .post('/account/department/createDepartment', data)
    .then(res => res.data);
};

export const updateDepartment = data => {
  return axios
    .put('/account/department/updateDepartment', data)
    .then(res => res.data);
};

export const inactiveDepartment = data => {
  return axios
    .put('/account/department/inactiveDepartment', null, {
      params: formatQuery(data),
    })
    .then(res => res)
    .catch(error => {
      if (error.response) {
        return error.response;
      }
    });
};

export const activeDepartment = data => {
  return axios
    .put('/account/department/activeDepartment', null, {
      params: formatQuery(data),
    })
    .then(res => res)
    .catch(error => {
      if (error.response) {
        return error.response;
      }
    });
};
