import { combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { configureStore } from 'redux-starter-kit';
import { reducer as appReducer } from './app';
import { reducer as accountReducer } from './account';
import { reducer as departmentReducer } from './department';
import { reducer as applicationsReducer } from './applications';
import { reducer as paymentReducer } from './payment';
import { reducer as ssoReducer } from './sso';

import logger from './logger';

const reducer = combineReducers({
  app: appReducer,
  account: accountReducer,
  department: departmentReducer,
  application: applicationsReducer,
  payment: paymentReducer,
  sso: ssoReducer,
});

console.log('node_env: ' + process.env.NODE_ENV);

const middleware =
  process.env.NODE_ENV !== 'production' ? [logger, thunk] : [thunk];

const store = configureStore({
  reducer,
  middleware,
});

export default store;
