import axios from 'store/axios';
import { formatQuery } from '../../utils/utils';

export const getAllUserApps = () =>
  axios.get('/account/tenantApp/getAllUserApps').then(res => {
    const result = res.data;
    // console.log(result);
    return result;
  });

export const getUsersWithDepartment = data =>
  axios
    .get('/account/tenantApp/getAllUsersWithDepartment', {
      params: formatQuery(data),
    })
    .then(res => {
      // console.log(res);
      return res.data;
    });

export const addUsersToApp = data => {
  return axios
    .post('/account/tenantApp/addUsersToApp', data)
    .then(res => res.data);
};

export const getTenantApp = data =>
  axios
    .get('/account/tenantApp/getTenant', {
      params: formatQuery(data),
    })
    .then(res => {
      const result = res.data;
      // console.log(result);
      return result;
    });

export const uploadTenantLogo = formData => {
  return axios
    .post('/account/tenantApp/uploadTenantLogo', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => res.data);
};

export const deleteUserInApp = formData => {
  return axios
    .post('/account/tenantApp/deleteUserInApp', formData)
    .then(res => res.data);
};
