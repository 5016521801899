import createOperation from '../createOperation';
import slice from './slice';
import * as service from '../payment/service';
import moment from 'moment';

const { actions } = slice;

export const listPaymentHistory = createOperation({
  actions: {
    successAction: actions.listPaymentHistorySuccess,
  },
  async process({ payload: { startDate, endDate } }) {
    if (startDate === null || startDate === undefined) {
      startDate = moment(new Date()).subtract(1, 'month').toDate().getTime();
    }
    if (endDate === null || endDate === undefined) {
      endDate = new Date().getTime();
    }
    const params = {};
    params.startDate = startDate;
    params.endDate = endDate;
    // params.startDate = 1611853200000;
    // params.endDate = 1614445200000;

    return await service.getPaymentHistory(params);
  },
});

export const listPaymentInfo = createOperation({
  actions: {
    successAction: actions.listPaymentInfoSuccess,
  },
  async process({ payload: { startDate, endDate } }) {
    if (startDate === null || startDate === undefined) {
      startDate = moment(new Date()).subtract(1, 'month').toDate().getTime();
    }
    if (endDate === null || endDate === undefined) {
      endDate = new Date().getTime();
    }
    const params = {};
    params.startDate = startDate;
    params.endDate = endDate;
    // params.startDate = 1611853200000;
    // params.endDate = 1614445200000;

    return await service.getPaymentInfo(params);
  },
});
