import createOperation from '../createOperation';
import * as applicationService from './service';
import slice from './slice';
import { openAlertModal } from 'utils/helper';
import _ from 'lodash';
const { actions } = slice;

export const listUsersApps = createOperation({
  actions: {
    successAction: actions.listUsersAppsSuccess,
  },
  async process() {
    return await applicationService.getAllUserApps();
  },
});

export const listUsersWithDepartment = createOperation({
  actions: {
    successAction: actions.listUsersWithDepartmentSuccess,
  },
  async process({ payload: { appId } }) {
    const params = {};
    params.appId = appId;
    // console.log('params listUsersWithDepartment', params);
    return await applicationService.getUsersWithDepartment(params);
  },
});

export const addUsersToApp = createOperation({
  async process({ payload: { tenant, user, appRole } }) {
    if (user != null && user.length > 0) {
      const params = {};

      params.appId = tenant.appId;
      params.appRole = appRole;
      params.appCode = tenant.appCode;
      params.users = [];

      _.forEach(user, obj => {
        const u = {};
        u.id = obj.id;
        u.fullName = obj.fullName;
        params.users.push(u);
      });

      const res = await applicationService.addUsersToApp(params);
      if (res != null) {
        let message_success = 'Thêm người dùng thành công!';
        if (appRole === 'admin') {
          message_success = 'Thêm admin thành công!';
        }
        openAlertModal('success', 'Thông báo!', message_success);
        return res;
      }
    } else {
      let errorMessage = 'Vui lòng nhập người dùng muốn phân quyền!';
      if (appRole === 'admin') {
        errorMessage = 'Vui lòng nhập admin muốn phân quyền!';
      }
      openAlertModal('error', 'Chú ý!', errorMessage);
      return null;
    }
  },
});

export const deleteUserInApp = createOperation({
  async process({ payload: { userId, appId, appRole } }) {
    let formData = new FormData();
    formData.set('userId', userId);
    formData.set('appId', appId);
    const res = await applicationService.deleteUserInApp(formData);
    if (res != null) {
      if (appRole === 'admin') {
        openAlertModal('success', 'Thông báo!', 'Xóa quyền Admin thành công');
      } else if (appRole === 'member') {
        openAlertModal(
          'success',
          'Thông báo!',
          'Xóa quyền Thành viên thành công',
        );
      }
      return res;
    }
  },
});

export const getTenantApp = createOperation({
  actions: {
    successAction: actions.tenantAppSuccess,
  },
  async process() {
    const result = await applicationService.getTenantApp();
    return result;
  },
});

export const uploadTenantLogo = createOperation({
  actions: {
    successAction: actions.uploadTenantLogoSuccess,
  },
  async process({ payload: { file } }) {
    let formData = new FormData();
    formData.append('file', file);
    const res = await applicationService.uploadTenantLogo(formData);
    if (res != null) {
      openAlertModal(
        'success',
        'Thông báo!',
        'Upload logo doanh nghiệp thành công',
      );
      return res;
    }
  },
});
