import { sprintf } from 'sprintf-js';

import messages from './errors';

export function getErrorMessage(errorCode, params) {
  console.log('errorCode', errorCode);
  const message = messages[errorCode];
  console.log('message', message);
  if (!params) {
    return message;
  }

  try {
    return sprintf(message, params);
  } catch (e) {
    return message;
  }
}
