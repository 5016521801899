import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import en from './en.json';
import vi from './vi.json';

const translationsJson = {
  en: {
    translation: en,
  },
  vi: {
    translation: vi,
  },
};
export const translations = {};

export const convertLanguageJsonToObject = (obj, dict, current) => {
  Object.keys(obj).forEach(key => {
    const currentLookupKey = current ? `${current}.${key}` : key;
    if (typeof obj[key] === 'object') {
      dict[key] = {};
      convertLanguageJsonToObject(obj[key], dict[key], currentLookupKey);
    } else {
      dict[key] = currentLookupKey;
    }
  });
};

export const i18n = i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init(
    {
      resources: translationsJson,
      fallbackLng: 'en',
      debug:
        process.env.NODE_ENV !== 'production' &&
        process.env.NODE_ENV !== 'test',

      interpolation: {
        escapeValue: false,
      },
      // react-i18next options
      react: {
        wait: true,
      },
    },
    () => {
      convertLanguageJsonToObject(en, translations);
    },
  );
